import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { textFadingVariant } from "../../../../constants/animations";
import classNames from "classnames";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

export const CareerForm = ({ lang, role }) => {
	const { register, handleSubmit, reset } = useForm();

	const onSubmit = (data) => {
		console.log(`${process.env.GATSBY_API_URL} hello`);
		const toastId = toast.loading(
			lang === "ar" ? "جاري الإرسال.." : "Submitting..."
		);
		let formData = new FormData();

		formData.append("cv", file);
		formData.append("role", role);
		// formData.append("body", data);
		for (let key in data) {
			formData.append(key, data[key]);
		}
		axios
			.post(`https://kashat-api.kashat.com.eg/api/career`, formData, {
				"Content-Type": "multipart/form-data",
			})
			.then((res) => {
				toast.dismiss(toastId);
				toast.success(
					lang === "ar"
						? "تم إرسال معلوماتك بنجاح.."
						: "Your information has been submitted..",
					{
						duration: 2500,
						iconTheme: {
							primary: "#F6B904",
						},
					}
				);
				setFile(null);
				setFileName(lang === "ar" ? "إختيار الملف" : "Choose File");
				fileRef.current.value = null;
				reset();
			})
			.catch(() => {
				toast.dismiss(toastId);
				toast.error(
					lang === "ar" ? "لقد حدث خطأ.." : "Something wrong happened.."
				);
			})
			.finally(() => {});
	};

	const buttonClass = `py-5 px-6 rounded-xl placeholder-[#9099A9] bg-white focus:outline-none w-full `;

	// resume file
	const fileRef = useRef();
	const [fileName, setFileName] = useState("");
	const [file, setFile] = useState(null);
	const handleChange = (e) => {
		const [file] = e.target.files;
		setFileName(file.name);
		setFile(file);
	};
	return (
		<>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className="p-8  space-y-8   "
				autoComplete="off"
			>
				<div className="grid lg:flex gap-8 items-center">
					<div className="  flex flex-col    w-full">
						<motion.p
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants={textFadingVariant}
							className="font-semibold  leading-none rtl:leading-normal mb-6 text-xl"
						>
							{lang === "ar" ? "الإسم كامل" : "Full Name"}
						</motion.p>
						<input
							type="string"
							placeholder={lang === "ar" ? "إسمك الكامل" : "Your Full Name"}
							{...register("name", { required: true })}
							className={buttonClass}
						/>
					</div>
					<div className=" flex flex-col w-full">
						<motion.p
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants={textFadingVariant}
							className="font-semibold  leading-none rtl:leading-normal text-xl mb-6"
						>
							{lang === "ar" ? "الإيميل الشخصي" : "Emaill Address"}
						</motion.p>
						<input
							type="string"
							placeholder={lang === "ar" ? "إيميلك" : "Your Email"}
							{...register("email", { required: true })}
							className={buttonClass}
						/>
					</div>
				</div>

				<div className="  flex flex-col   ">
					<motion.p
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants={textFadingVariant}
						className="font-semibold  leading-none rtl:leading-normal mb-6 text-xl"
					>
						{lang === "ar"
							? "ليه عاوز تشتغل معانا؟"
							: "Why do you want to work here?"}
					</motion.p>
					<textarea
						placeholder={lang === "ar" ? "إكتب هنا" : "Type here"}
						{...register("message", { required: true })}
						className={classNames(
							buttonClass,
							"resize-none h-52 border-0 ring-0 focus:ring-0 focus:border-0 focus:outline-none"
						)}
					/>
				</div>
				{/* file upload */}
				<div>
					<motion.p
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants={textFadingVariant}
						className="font-semibold  leading-none rtl:leading-normal mb-6 text-xl"
					>
						{lang === "ar" ? "قم برفق السيرة الذاتية" : "Attach Resume"}
					</motion.p>
					<div className="flex flex-col md:flex-row gap-8 ">
						<div className="w-full  rounded-xl bg-white   text-[#B0B7C3]    py-5   px-6  group  transition-all duration-200">
							{fileName !== ""
								? fileName
								: lang === "ar"
								? "إختيار الملف"
								: "Choose file"}
						</div>
						<button
							className=" font-semibold rounded-xl bg-[#F6B904]   text-white flex justify-center items-center  py-5   px-20  group  transition-all duration-200"
							onClick={(e) => {
								e.preventDefault();
								fileRef.current.click();
							}}
						>
							{lang === "ar" ? "تصفح" : "Browse"}
						</button>
						<input
							ref={fileRef}
							onChange={handleChange}
							type="file"
							name="cv"
							id="cv"
							hidden
							required
						/>
					</div>
				</div>
				<div className="flex items-center justify-end">
					<button
						type="submit"
						className="mt-8 md:mt-16 font-semibold rounded-xl bg-[#F6B904]   text-white flex justify-center items-center  py-5  px-12  sm:w-auto  md:px-20   group  transition-all duration-200"
					>
						<span>{lang === "ar" ? "إرسال الطلب" : "Submit Application"}</span>
					</button>
				</div>
			</form>
			<Toaster position="top-center" />
		</>
	);
};
