import React, { useRef } from "react";
import Layouts from "../components/Layouts";
import { Cta } from "../components/Cta";
import { graphql } from "gatsby";
import "moment/locale/ar";
import "moment/locale/en-gb";
import { CareerForm } from "../components/Screens/Careers/CareerForm";
import { PageHeader } from "../components/Atomics/PageHeader";
import Seo from "../components/Seo";

const CareerPage = ({ data }) => {
	const formRef = useRef();
	const scrollToElement = () =>
		formRef.current.scrollIntoView({ behavior: "smooth" });
	const post = data && data.career;
	const lang = post.language.locale;
	return (
		<>
			<Seo title={`${post.title} - ${lang === "ar" ? "كاشات" : "Kashat"}`} />
			<Layouts lang={lang === "ar" ? "ar" : "en"}>
				<PageHeader
					miniHeader={lang === "ar" ? "وظائف" : "Careers"}
					header={lang === "ar" ? "ثقافة العمل في كاشات" : "The Kashat Culture"}
					text={
						lang === "ar"
							? "كاشات أول شركة تكنولوجيا مالية للهاتف، وعندها طموح كبير إنها تحل المشاكل الصعبة في المنطقة، وتقدم الحلول المالية ليها بحيث تكون من الأنظمة المالية اللي بتتميز بالتقدم والشمولية."
							: "Job Description"
					}
				/>
				<div className="w-9/12 my-12 mx-auto grid lg:grid-cols-[1fr,2fr] gap-10">
					<div>
						<div className="py-6 px-12 bg-[#F9FAFB]  rounded-3xl ">
							{post.title && (
								<h2
									className=" text-xl sm:text-3xl font-bold mb-3"
									dangerouslySetInnerHTML={{ __html: post.title }}
								/>
							)}
							<div className={"flex mb-4"}>
								<p className="text-xl font-medium">
									{post.departments && post.departments.length > 1 && (
										<span>
											{post.departments.nodes.map((tag) => tag.name)},
										</span>
									)}

									{post.careerPage.location && (
										<span> {post.careerPage.location} </span>
									)}
								</p>
							</div>
							<button
								onClick={() => {
									scrollToElement();
								}}
								className="font-semibold py-4 md:py-5 px-12 md:text-xl bg-[#F6B904] rounded-xl text-white font-sembibold"
							>
								{lang === "ar" ? "قدم الان " : "Apply Now"}
							</button>
						</div>
					</div>
					{post.careerPage.jobDescription && (
						<div
							className="prose lg:prose-lg  w-full max-w-none mb-10"
							dangerouslySetInnerHTML={{
								__html: post.careerPage.jobDescription,
							}}
						/>
					)}
				</div>
				<div className=" bg-[#F9FAFB] py-20" ref={formRef}>
					<div className="w-11/12 xl:w-9/12 mx-auto">
						<CareerForm role={post.title} lang={lang} />
					</div>
				</div>
				<Cta
					miniHeader={
						lang === "ar"
							? "كاشات بتساعد في عملية تعزيز وصول العملاء إلى الإمكانيات المالية الرقمية"
							: `EGYPT's First Nano Financial Service Provider`
					}
					header={
						lang === "ar"
							? "كاشات بتساعد في عملية تعزيز وصول العملاء إلى الإمكانيات المالية الرقمية"
							: "Powering Access to Digital Financial Possibilities"
					}
					subHeader={
						lang === "ar"
							? "كاشات هي مفتاح الدخول الرئيسي للشمول المالي للأشخاص اللي دخلهم محدود أو اللي مش بيتعاملوا مع البنوك في مصر. وعشان كدا كاشات بتوفرلهم تمويلات سريعة وصغيرة وبتكون في وقت قصير، وكمان تمويلات إنتاجية للمشاريع."
							: "Kashat serves as the entry point to financial inclusion for the un- and underbanked population in Egypt by offering instant, small, short-term, productive loans."
					}
				/>
			</Layouts>
		</>
	);
};
export default CareerPage;
export const query = graphql`
	query CareerTemplateQuery($id: String!) {
		career: wpCareer(id: { eq: $id }) {
			id
			language {
				code
				locale
			}
			date
			title
			slug
			title
			id
			departments {
				nodes {
					name
				}
			}

			careerPage {
				shortDescription
				jobDescription
				location
			}
		}
	}
`;
